import {
  mdiViewDashboard,
  mdiAccount,
  mdiHeart,
  mdiPackage,
  mdiClipboardFlow,
  mdiStore,
} from '@mdi/js'

const adminNavItems = [
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Dashboard',
    icon: mdiViewDashboard,
    to: 'admin-dashboard',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Users',
    icon: mdiAccount,
    to: 'admin-user',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Laporan Workflow',
    icon: mdiClipboardFlow,
    to: 'admin-workflow',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Integrasi Tokopedia',
    icon: mdiStore,
    to: 'admin-tokopedia',
  },
]

const userNavItems = [
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Dashboard',
    icon: mdiViewDashboard,
    to: 'workspace-root',
  },
  {
    subheader: 'Manajemen',
    action: 'read',
    resource: 'Navbar',
  },
  {
    action: 'read',
    resource: 'Customer',
    title: 'Pelanggan',
    icon: mdiHeart,
    to: 'customers',
  },
  {
    action: 'read',
    resource: 'Product',
    title: 'Produk',
    icon: mdiPackage,
    to: 'products',
  },
  {
    widget: 'folder-subheader',
    title: 'Manajemen Tugas',
    action: 'read',
    resource: 'Navbar',
  },
  {
    widget: 'folder',
    action: 'read',
    resource: 'Navbar',
  },
  {
    subheader: 'Administrator',
    action: 'read',
    resource: 'Navbar',
  },
  {
    action: 'read',
    resource: 'User',
    title: 'Pengguna',
    icon: mdiAccount,
    to: 'users',
  },
]

export {
  adminNavItems,
  userNavItems,
}
