<template>
  <v-form
    ref="resetPasswordForm"
    lazy-validation
    class="d-flex flex-column"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <span class="text-caption mb-1">Password Baru</span>
        <v-text-field
          v-model="newPassword"
          outlined
          :type="showNewPassword ? 'text' : 'password'"
          placeholder="Password Baru"
          :append-icon="showNewPassword ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
          hide-details="auto"
          dense
          :rules="[
            required,
            lengthValidator(newPassword, 8),
          ]"
          @click:append="showNewPassword = !showNewPassword"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <span class="text-caption mb-1">Konfirmasi Password Baru</span>
        <v-text-field
          v-model="confirmPassword"
          outlined
          :type="showConfirmPassword ? 'text' : 'password'"
          placeholder="Konfirmasi Password"
          :append-icon="showConfirmPassword ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
          hide-details="auto"
          dense
          :rules="[
            required,
            confirmedValidator(confirmPassword, newPassword),
            lengthValidator(confirmPassword, 8),
          ]"
          @click:append="showConfirmPassword = !showConfirmPassword"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <span class="text-caption mb-1">Password Lama</span>
        <v-text-field
          v-model="oldPassword"
          outlined
          :type="showPassword ? 'text' : 'password'"
          placeholder="Password Lama"
          :append-icon="showPassword ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
          hide-details="auto"
          dense
          :rules="[
            required,
          ]"
          @click:append="showPassword = !showPassword"
        />
      </v-col>
    </v-row>

    <div class="mt-6 ml-md-auto">
      <v-btn
        color="primary"
        :loading="loadingUpdate"
        @click="updatePass"
      >
        Reset Password
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { required, confirmedValidator, lengthValidator } from '@core/utils/validation'
import { apolloClient } from '@/vue-apollo'
import { updatePassword } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'

export default {
  setup() {
    const resetPasswordForm = ref()
    const loadingUpdate = ref(false)
    const oldPassword = ref('')
    const newPassword = ref('')
    const confirmPassword = ref('')

    const showPassword = ref(false)
    const showNewPassword = ref(false)
    const showConfirmPassword = ref(false)

    const updatePass = () => {
      if (resetPasswordForm.value.validate()) {
        loadingUpdate.value = true

        apolloClient.mutate({
          mutation: updatePassword,
          variables: {
            old_password: oldPassword.value,
            new_password: newPassword.value,
            confirm_password: confirmPassword.value,
          },
        }).then(() => {
          oldPassword.value = ''
          newPassword.value = ''
          confirmPassword.value = ''
          resetPasswordForm.value.resetValidation()

          Vue.notify({
            title: 'Sukses',
            text: 'Berhasil mengubah password!',
          })

          loadingUpdate.value = false
        }).catch(err => {
          loadingUpdate.value = false

          errorHandling(err)
        })
      }
    }

    return {
      resetPasswordForm,
      loadingUpdate,
      oldPassword,
      newPassword,
      confirmPassword,
      showPassword,
      showNewPassword,
      showConfirmPassword,

      updatePass,

      required,
      confirmedValidator,
      lengthValidator,

      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style>

</style>
