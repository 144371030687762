import gql from 'graphql-tag'

const automationObjectsV2 = gql`
  query automationObjectsV2(
    $pagination: pagination!
  ) {
    automationObjectsV2(
      pagination: $pagination
    ){
      id
      name
      platform {
        id
        name
      }
    }
  }
`
export default automationObjectsV2
