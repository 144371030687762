<template>
  <div>
    <v-menu
      bottom
      right
      offset-y
      :nudge-bottom="8"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          class="ml-2"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon size="28">
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list nav>
          <div
            v-for="(item, i) in quickAddItems"
            :key="i"
          >
            <v-list-item
              v-if="$can('create', item.name)"
              :key="i"
              class="d-flex pl-4 pr-6"
              @click="item.action"
            >
              <v-icon
                class="mr-4"
              >
                {{ item.icon }}
              </v-icon>
              <span class="text-subtitle-2">
                {{ item.title }}
              </span>
            </v-list-item>
          </div>
        </v-list>
      </v-card>
    </v-menu>
    <customer-form ref="customerForm" />
    <product-form ref="productForm" />
    <job-form ref="jobForm" />
    <prospect-form ref="prospectForm" />
    <task-form ref="taskForm" />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiPlus, mdiHeart, mdiPackage,
  mdiTicketAccount,
} from '@mdi/js'
import CustomerForm from '@/views/customer/CustomerForm.vue'
import ProductForm from '@/views/product/ProductForm.vue'
import JobForm from '@/views/job/components/JobForm.vue'

export default {
  components: {
    CustomerForm,
    ProductForm,
    JobForm,
  },
  setup() {
    const customerForm = ref()
    const productForm = ref()
    const jobForm = ref()

    const quickAddItems = [
      {
        title: 'Pelanggan',
        name: 'Customer',
        icon: mdiHeart,
        action: () => {
          customerForm.value.show()
        },
      },
      {
        title: 'Produk',
        name: 'Product',
        icon: mdiPackage,
        action: () => {
          productForm.value.show()
        },
      },
      {
        title: 'Job',
        name: 'Customer',
        icon: mdiTicketAccount,
        action: () => {
          jobForm.value.show()
        },
      },
    ]

    return {
      customerForm,
      productForm,
      jobForm,
      quickAddItems,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>

<style>

</style>
