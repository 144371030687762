<template>
  <div>
    <v-menu
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text ms-4"
          v-on="on"
        >
          <v-img
            v-if="user && user.photo !== null"
            :src="user.photo"
          />
          <v-icon
            v-else
            size="20"
            color="primary"
          >
            {{ icons.mdiAccount }}
          </v-icon>
        </v-avatar>
      </template>
      <v-list>
        <div
          v-if="user"
          class="pb-3 pt-2 d-flex flex-column"
        >
          <div class="d-flex align-center mb-3">
            <v-avatar
              offset-x="12"
              offset-y="12"
              size="40px"
              color="primary"
              class="ms-3 v-avatar-light-bg primary--text"
            >
              <v-img
                v-if="user.photo !== null"
                :src="user.photo"
              />
              <span v-else>
                {{ avatarText(`${user.first_name} ${user.last_name}`) }}
              </span>
            </v-avatar>
            <div
              class="d-flex flex-column justify-center ms-2"
              style="vertical-align: middle"
            >
              <span class="text-subtitle-2 text--primary font-weight-semibold mb-1">{{ user.first_name }} {{ user.last_name }}</span>
              <v-chip
                x-small
                color="primary"
                class="align-self-start"
              >
                <span class="text-caption">{{ role.name }}</span>
              </v-chip>
            </div>
          </div>
          <small class="text-capitalize ml-3">{{ user.email }}</small>
        </div>

        <v-card
          v-if="user"
          class="pa-2 mx-3 mb-2 d-flex justify-space-between align-center"
          outlined
        >
          <span class="text-subtitle-2 ms-1">Ketersediaan</span>
          <v-switch
            v-model="availabilitySelection"
            hide-details
            dense
            class="ma-0"
            @change="changeAvailability"
            @click.stop
          />
        </v-card>

        <!-- Profile -->
        <v-list-item @click="$refs.myProfile.show()">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Lihat Profil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- FAQ -->
        <v-list-item href="#">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiHelpCircleOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Bantuan</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2" />

        <!-- Logout -->
        <v-list-item @click="$store.dispatch('logout')">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <my-profile
      ref="myProfile"
      @changeSubscribe="changeSubscribe()"
    />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiAccount,
} from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { apolloClient } from '@/vue-apollo'
import { updateAvailabilityUser } from '@/graphql/mutations'
import MyProfile from '@/views/profile/MyProfile.vue'

export default {
  components: {
    MyProfile,
  },
  setup(props, { emit }) {
    const user = computed(() => store.state.auth.userData)
    const role = computed(() => store.state.workspace.selectedWorkspace.role)

    const availabilitySelection = computed({
      get() {
        return store.state.auth.userData.availability
      },
      set(val) {
        store.state.auth.userData.availability = val
      },
    })

    const changeAvailability = data => {
      apolloClient.mutate({
        mutation: updateAvailabilityUser,
        variables: {
          availability: data !== null ? data : false,
        },
      }).then(() => {
        store.state.auth.userData.availability = data !== null ? data : false
      })
    }

    const changeSubscribe = () => {
      emit('changeSubscribe')
    }

    return {
      user,
      role,
      availabilitySelection,

      changeAvailability,
      avatarText,

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiAccount,
      },
      changeSubscribe,
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
