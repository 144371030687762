import gql from 'graphql-tag'

const assignProductTypes = gql`
  mutation(
    $product_id: Float!
    $productType: [Int!]!
    $workspace_id: Float!
  ){
    assignProductType(
      product_id: $product_id
      productType: $productType
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default assignProductTypes
