import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c('draggable',{attrs:{"list":_vm.formData.priority,"animation":200,"ghost-class":"ghost-card","group":"priority"}},_vm._l((_vm.formData.priority),function(priority,index){return _c(VCard,{key:index,staticClass:"py-6 px-2 mb-2",attrs:{"outlined":""}},[_c(VRow,[_c(VCol,{staticClass:"d-flex flex-row-reverse flex-sm-row",attrs:{"cols":"12","sm":"6"}},[_c(VIcon,{staticClass:"ml-2 cursor-move ml-sm-0"},[_vm._v(" "+_vm._s(_vm.icons.mdiDrag)+" ")]),_c(VTextField,{staticClass:"ml-2",attrs:{"label":"Nama Prioritas (wajib diisi)","placeholder":"Input nama prioritas job","dense":"","hide-details":"auto","rules":[_vm.required]},model:{value:(priority.name),callback:function ($$v) {_vm.$set(priority, "name", $$v)},expression:"priority.name"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex"},[_c('color-picker',{staticClass:"ml-2 ml-sm-0",attrs:{"label":"Warna Priority (wajib diisi)","attach":false,"rules":[_vm.required]},model:{value:(priority.color),callback:function ($$v) {_vm.$set(priority, "color", $$v)},expression:"priority.color"}}),(_vm.formData.priority.length > 1)?_c('div',[(_vm.updateMode ? _vm.$can('update','Job') : _vm.$can('create','Job'))?_c(VIcon,{staticClass:"ml-2",on:{"click":function($event){return _vm.removePriority(index)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloseCircleOutline)+" ")]):_vm._e()],1):_vm._e()],1)])],1)],1)}),1),(_vm.updateMode ? _vm.$can('update','Job') : _vm.$can('create','Job'))?_c(VBtn,{attrs:{"block":"","outlined":"","color":"primary"},on:{"click":_vm.addPriority}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1):_vm._e()],1),_c('attribute-delete-confirmation',{ref:"attributeDeleteConfirmation",on:{"success":function($event){return _vm.updatePriority()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }