import gql from 'graphql-tag'

const products = gql`
  query products(
    $search: String
    $filter: filterProduct
    $workspace_id: Float!
  ) {
    products(
      search: $search
      filter: $filter
      workspace_id: $workspace_id
    ) {
      count
      products {
        id
        name
        description
        code_sku
        price
        status
        stock
        photo {
          id
          photo
          order_photo
        }
        type {
          id
          name
          color
        }
        created_at
        updated_at
        deleted_at
      }
    }
  }
`

export default products
