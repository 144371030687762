import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{ref:"productTypeInput",attrs:{"placeholder":"Tipe Produk","label":_vm.label,"outlined":"","dense":"","loading":_vm.loading,"items":_vm.types,"item-text":"name","item-value":"id","multiple":"","chips":"","deletable-chips":"","small-chips":"","hide-details":"auto"},on:{"update:search-input":_vm.onSearch,"update:list-index":_vm.onIndex,"keydown":_vm.onKeyDown},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.searchQuery && !_vm.readOnly)?_c('div',{staticClass:"d-flex justify-space-between px-4 py-2 cursor-pointer",on:{"click":_vm.addTag}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Tambahkan "),_c(VChip,{attrs:{"small":""}},[_vm._v(_vm._s(_vm.searchQuery))]),_vm._v(" dan pilih.")],1),_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1):_c('div',{staticClass:"px-4 py-2 text-subtitle-2 text--disabled text-center"},[_vm._v(" Tag tidak ditemukan. "+_vm._s(_vm.readOnly ? '' : 'Ketik sesuatu dan buat tag baru.')+" ")])]},proxy:true},(_vm.types.length)?{key:"append-item",fn:function(){return [(_vm.searchQuery && !_vm.isEmpty && !_vm.readOnly && !_vm.$can('create', 'CustomerType'))?_c('div',{staticClass:"d-flex justify-space-between px-4 py-2 cursor-pointer",on:{"click":_vm.addTag}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Tambahkan "),_c(VChip,{attrs:{"small":""}},[_vm._v(_vm._s(_vm.searchQuery))]),_vm._v(" dan pilih.")],1),_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1):_vm._e()]},proxy:true}:null,{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isHovered = ref.hover;
return [_c('div',{staticClass:"d-flex w-full justify-space-between py-2"},[_c(VChip,{attrs:{"small":"","color":item.color}},[_vm._v(" "+_vm._s(item.name)+" ")]),(!_vm.readOnly)?_c(VMenu,{attrs:{"close-on-content-click":false,"max-width":"290px","min-width":"290px","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VSlideXReverseTransition,{attrs:{"mode":"out-in"}},[_c(VIcon,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(isHovered),expression:"isHovered"}]},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsHorizontal)+" ")])],1)]}}],null,true),model:{value:(_vm.vMenuModel[item.id]),callback:function ($$v) {_vm.$set(_vm.vMenuModel, item.id, $$v)},expression:"vMenuModel[item.id]"}},[_c(VCard,{staticClass:"pa-3"},[_c(VTextField,{attrs:{"dense":"","disabled":!_vm.$can('update', 'CustomerType'),"placeholder":"Update Tags","hide-details":"","outlined":"","append-icon":item.name && _vm.$can('update', 'CustomerType') ? _vm.icons.mdiCheck : null},on:{"keydown":function($event){$event.keyCode === 13 ? _vm.updateTag(item) : null},"blur":_vm.fetchTypes,"click:append":function($event){return _vm.updateTag(item)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),( _vm.$can('update', 'CustomerType'))?_c('color-picker',{staticClass:"mt-6 mb-4",attrs:{"attach":false},on:{"input":function($event){$event ? _vm.updateTag(item, $event) : null}},model:{value:(item.color),callback:function ($$v) {_vm.$set(item, "color", $$v)},expression:"item.color"}}):_vm._e(),(_vm.$can('del', 'ProductType'))?_c(VBtn,{staticClass:"mt-2",attrs:{"color":"error","block":"","small":"","outlined":""},on:{"click":function($event){return _vm.deleteTag(item)}}},[_vm._v(" Hapus Tag ")]):_vm._e()],1)],1):_vm._e()],1)]}}],null,true)})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":item.color}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"append-outer",fn:function(){return [_vm._t("match")]},proxy:true}],null,true),model:{value:(_vm.inputData),callback:function ($$v) {_vm.inputData=$$v},expression:"inputData"}})}
var staticRenderFns = []

export { render, staticRenderFns }