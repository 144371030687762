import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardText,{staticClass:"d-flex flex-column h-full"},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.transactionList,"items-per-page":10,"item-key":"id","footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Jumlah data',
    },"server-items-length":_vm.transactionCount,"mobile-breakpoint":"0","disable-sort":"","height":"620px","fixed-header":"","disable-filtering":"","loading":_vm.loadingTransactions,"options":_vm.state.pagination.value},on:{"update:options":function($event){return _vm.$set(_vm.state.pagination, "value", $event)},"update:page":_vm.loadTransactions,"update:items-per-page":_vm.loadTransactions},scopedSlots:_vm._u([{key:"item.midtrans_order_id",fn:function(props){return [_c('div',{staticClass:"d-flex py-2"},[_vm._v(" "+_vm._s(props.item.midtrans_order_id)+" ")])]}},{key:"item.status",fn:function(props){return [_c('div',{staticClass:"d-flex py-2"},[_c(VChip,{staticClass:"text-capitalize",attrs:{"color":props.item.status.color,"outlined":""}},[_vm._v(" "+_vm._s(props.item.status.name)+" ")])],1)]}},{key:"item.payment_type",fn:function(props){return [_c('div',{staticClass:"d-flex py-2"},[(props.item.payment_type.name)?_c(VChip,{staticClass:"text-uppercase",attrs:{"color":props.item.payment_type.color,"outlined":""}},[_vm._v(" "+_vm._s(props.item.payment_type ? props.item.payment_type.name : '')+" ")]):_vm._e()],1)]}},{key:"item.created_at",fn:function(props){return [_c('div',{staticClass:"d-flex py-2"},[_vm._v(" "+_vm._s(_vm.formatDate(props.item.created_at, { year: 'numeric', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' }))+" ")])]}},{key:"item.gross_amount",fn:function(props){return [_c('div',{staticClass:"d-flex py-2"},[_vm._v(" "+_vm._s(_vm.formatCurrency(props.item.gross_amount))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }