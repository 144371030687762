import gql from 'graphql-tag'

const updateLexorank = gql`
  mutation updateObjectLexorank(
    $lexorank: String!
    $job_id: Float
    $task_id: Float
    $workspace_id: Float!
  ) {
    updateObjectLexorank(
      lexorank: $lexorank
      job_id: $job_id
      task_id: $task_id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default updateLexorank
