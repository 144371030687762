import gql from 'graphql-tag'

const deleteProductTypes = gql`
  mutation(
    $id: Float!
    $workspace_id: Float!
  ) {
    deleteProductTypes(
      id: $id
      workspace_id: $workspace_id
    ) {
      status
      data
      msg
    }
  }
`

export default deleteProductTypes
