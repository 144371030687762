import gql from 'graphql-tag'

const profileInfo = gql`
  query {
    profileInfo {
      id
      phone
      email
      first_name
      last_name
      photo
      is_admin
      notify_task_assignment
      notify_comment
      notify_job_assignment
      availability
      weekly_reminder_hour
      last_seen_at
      created_at
      updated_at
      kuota_file
      subscription_ends_at
      payment_interval
      renew_subscription_type
      is_auto_subscribe
      subscription {
        id
        name
        price_month
        price_year
        limit_workspace
      }
    }
  }
`

export default profileInfo
