import gql from 'graphql-tag'

const proceedJobPriority = gql`
  mutation proceedJobPriority (
    $priority_id: Float
    $id: Float!
    $workspace_id: Float!
    $lexorank: String!
  ) {
    proceedJobPriority (
      priority_id: $priority_id
      workspace_id: $workspace_id
      lexorank: $lexorank
      id: $id
    ) {
      status
      msg
      data
    }
  }
`

export default proceedJobPriority
