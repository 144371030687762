import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VSlideYTransition,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"5"}},[_c(VTextField,{staticClass:"mt-6",attrs:{"dense":"","outlined":"","hide-details":"","hint":"Cari pengguna...","label":"Filter Berdasarkan Nama"},on:{"input":_vm.debouncedFetchWorkspaceMember},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMagnify)+" ")])],1)]},proxy:true}]),model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}})],1)],1)],1),_c(VDataIterator,{attrs:{"items":_vm.members,"items-per-page":10,"show-select":"","disable-sort":"","footer-props":{
      'items-per-page-options': [10, 20, 30],
      'items-per-page-text': 'Jumlah data',
    },"server-items-length":_vm.memberCount,"mobile-breakpoint":"0","loading":_vm.loading,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('PerfectScrollbar',{staticClass:"ps-user-workspace mt-2 pa-2",attrs:{"options":{suppressScrollX:true, useBothWheelAxes:false}}},[_c(VRow,_vm._l((props.items),function(member,index){return _c(VCol,{key:index,staticClass:"pa-1",attrs:{"cols":"12","lg":"6"}},[_c(VCard,{attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex pa-3 justify-space-between"},[_c('div',{staticClass:"d-flex"},[_c(VAvatar,{attrs:{"size":"35"}},[_c(VImg,{attrs:{"src":require("@/assets/images/avatars/5.png")}})],1),_c('div',{staticClass:"ml-4"},[_c('div',[_c('p',{staticClass:"mb-0 mr-2 body-2 font-weight-semibold d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(member.user ? member.user.name : member.email)+" ")]),_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(member.user && _vm.userLogin.id === member.user.id),expression:"member.user && userLogin.id === member.user.id"}],attrs:{"x-small":"","color":"primary"}},[_vm._v(" Anda ")])],1),_c('span',{staticClass:"text-caption text--disabled"},[_vm._v(_vm._s(member.user ? member.user.email : member.email))]),_c(VSelect,{staticClass:"mt-6",staticStyle:{"max-width":"200px"},attrs:{"dense":"","items":_vm.roles,"value":member.role.id,"disabled":!_vm.$can('update','Role') || member.role.id === 1,"item-value":"id","item-text":"name","label":"Role","return-object":"","hide-details":"auto"},on:{"change":function (data) { return _vm.updateUserRole(member.user, data); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
    var on = ref.on;
    var attr = ref.attr;
return [_c(VListItem,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.id !== 1),expression:"item.id !== 1"}],attrs:{"value":item}},'v-list-item',attr,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex align-center"},[(member.is_pending)?_c('div',[_c(VChip,{attrs:{"small":"","color":"warning","outlined":"","label":""}},[_vm._v(" Pending ")])],1):_vm._e(),(member.user && _vm.userLogin.id !== member.user.id && member.role.id > 2 && _vm.userLoginRole.id <= 1)?_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"plain":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c(VList,[_c(VListItem,[_c(VListItemTitle,{staticClass:"error--text",on:{"click":function($event){return _vm.removeUser(member.user)}}},[_vm._v(" Keluarkan dari Workspace ")])],1)],1)],1):_vm._e()],1)])])],1)}),1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }