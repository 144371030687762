<template>
  <div>
    <v-dialog
      v-model="isOpen"
      max-width="800"
      scrollable
      :retain-focus="false"
      @input="!$event ? reset() : null"
    >
      <v-card min-height="500">
        <div class="d-flex justify-space-between pa-4">
          <v-row>
            <v-col cols="2">
              <span class="text-h6 font-medium primary--text">{{ updateMode ? 'Ubah Job' : 'Buat Job' }}</span>
            </v-col>
            <v-col
              cols="5"
            >
              <treeselect
                v-if="!loadingDetail"
                v-model="jobType"
                :options="folderList"
                :multiple="false"
                :disable-branch-nodes="true"
                :normalizer="normalizer"
                :clearable="false"
                :required="true"
                placeholder="Pilih job type"
                :disabled="updateMode"
              />
            </v-col>
          </v-row>
          <v-icon
            @click="close()"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </div>
        <v-card-text
          v-if="!loadingDetail"
          class="job-form-content flex-column d-flex justify-space-between"
        >
          <v-form
            ref="jobForm"
            lazy-validation
            @submit.prevent="submitForm"
          >
            <v-tabs
              v-model="activeTab"
              grow
              flat
              style="box-shadow: none !important;"
            >
              <v-tab>
                Detail
              </v-tab>
              <v-tab>
                Produk
              </v-tab>
              <v-tab-item
                class="pt-5"
                eager
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="formData.name"
                      :rules="[required]"
                      label="Nama (wajib diisi)"
                      placeholder="Input nama"
                      outlined
                      dense
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <div class="d-flex">
                      <date-range-picker
                        :value="{
                          startDate: formData.start_date,
                          endDate: formData.expected_close_date,
                        }"
                        :rules="[required]"
                        :placeholder="'Tanggal Mulai - Tanggal Akhir'"
                        @update="setDateRange($event)"
                        @select="!formData.expected_close_date && !formData.start_date ? setDateRange($event) : null"
                      />
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="setDateRange({ startDate: null, endDate: null })"
                          >
                            <v-icon>
                              {{ icons.mdiClose }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Kosongkan tanggal mulai & tanggal akhir</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <form-ckeditor
                      :content.sync="formData.description"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <customer-auto-suggest
                      v-model="formData.customer"
                      :return-object="true"
                    />
                  </v-col>
                  <v-col
                    v-show="false"
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="formData.job_priority_id"
                      :items="priorityOptions"
                      label="Prioritas"
                      outlined
                      clearable
                      item-text="name"
                      item-value="id"
                      dense
                      hide-details="auto"
                      :disabled="!updateMode && !jobType"
                    >
                      <template #item="{ item }">
                        <v-chip
                          small
                          :color="item.color"
                        >
                          {{ item.name }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="formData.job_status_id"
                      :items="statusOptions"
                      label="Status Job (wajib diisi)"
                      outlined
                      item-text="name"
                      item-value="id"
                      dense
                      hide-details="auto"
                      :rules="[required]"
                      :disabled="!jobType"
                    >
                      <template #item="{ item }">
                        <v-chip
                          small
                          :color="item.color"
                        >
                          {{ item.name }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    v-show="false"
                    cols="12"
                    md="6"
                  >
                    <UserTeamAutoSuggest
                      v-if="!loadingDetail"
                      v-model="formData.assignTo"
                      label="Ditugaskan ke"
                      :job-type-id="jobType"
                      :attach="true"
                      show-teams
                      multiple
                    />
                  </v-col>
                  <v-col
                    v-show="false"
                    cols="12"
                    md="6"
                  >
                    <v-card
                      outlined
                      class="pa-3 mb-4 d-flex flex-column"
                    >
                      <span class="text-subtitle-2 font-medium mb-3">Atribut Lainnya</span>
                      <PerfectScrollbar
                        v-if="updateMode || !updateMode && jobType"
                        class="custom-attribute-scroll-bar"
                      >
                        <div
                          v-for="(attribute, index) in customAttributesArray"
                          :key="attribute.custom_attribute.id"
                          class="mb-2"
                        >
                          <v-text-field
                            v-if="attribute.custom_attribute.data_type.id === 1 || attribute.custom_attribute.data_type.id === 2"
                            v-model="customAttributesArray[index].value"
                            :type="attribute.custom_attribute.data_type.id === 1 ? 'text' : 'number'"
                            :label="attribute.custom_attribute.name"
                            :placeholder="'Input ' + attribute.custom_attribute.name + ' job'"
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-2"
                          />
                          <div class="mb-2">
                            <DateTimePicker
                              v-if="attribute.custom_attribute.data_type.id === 3"
                              v-model="customAttributesArray[index].value"
                              :label="attribute.custom_attribute.name"
                              :placeholder="attribute.custom_attribute.name"
                            />
                          </div>
                          <v-autocomplete
                            v-if="attribute.custom_attribute.data_type.id === 4 || attribute.custom_attribute.data_type.id === 5"
                            v-model="customAttributesArray[index].value"
                            :items="attribute.custom_attribute.options"
                            :label="attribute.custom_attribute.name"
                            :placeholder="attribute.custom_attribute.name"
                            outlined
                            dense
                            clearable
                            hide-details="auto"
                            :multiple="attribute.custom_attribute.data_type.id === 5"
                            :small-chips="attribute.custom_attribute.data_type.id === 5"
                            item-text="name"
                            item-value="id"
                            class="mb-2"
                          />
                        </div>
                      </PerfectScrollbar>
                      <v-btn
                        text
                        elevation="0"
                        block
                        outlined
                        color="primary"
                        :disabled="!updateMode && !jobType"
                        @click="updateMode ? $refs.customAttributesList.open() : $refs.customAttributeForm.open()"
                      >
                        <v-icon>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item eager>
                <ProductAutoSuggest
                  class="mt-4"
                  :value="product"
                  :emit-mode="true"
                  :multiple="true"
                  @input="productInput"
                />
                <PerfectScrollbar
                  v-if="product.length"
                  :key="productKey"
                  class="product-scroll-bar mt-4 pr-4"
                >
                  <v-card
                    v-for="data in product"
                    :key="data.id"
                    class="mb-4"
                    outlined
                  >
                    <v-card-text>
                      <div class="d-flex">
                        <div class="d-flex flex-column w-full">
                          <div class="d-flex justify-space-between w-full ">
                            <router-link
                              target="_blank"
                              :to="{ name: 'product-detail', params: { id: data.id} }"
                            >
                              <p class="body-2 font-weight-bold primary--text">
                                {{ data.name }}
                              </p>
                            </router-link>
                            <div>
                              <v-chip
                                v-for="type in data.type"
                                :key="type.id"
                                small
                                :color="type.color"
                                class="ml-3 font-weight-bold"
                              >
                                {{ type.name }}
                              </v-chip>
                            </div>
                          </div>
                          <v-row dense>
                            <v-col
                              cols="12"
                              sm="6"
                            >
                              <p class="mb-1 caption font-weight-bold">
                                Kode SKU
                              </p>
                              <p
                                class="caption"
                              >
                                {{ data.code_sku ? data.code_sku : "Tidak ada kode SKU" }}
                              </p>
                              <p class="mb-1 caption font-weight-bold">
                                Harga
                              </p>
                              <p
                                class="caption"
                              >
                                {{ data.price ? formatIDR(data.price) : 0 }}
                              </p>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              class="d-flex flex-column align-end justify-end"
                            >
                              <p class="mb-1 caption font-weight-bold">
                                Kuantitas
                              </p>
                              <Counter
                                :id="data.id"
                                :qty="data.quantity ? data.quantity:1"
                                @changeQty="changeQuantity"
                              />
                              <v-icon
                                color="error"
                                @click="removeProduct(data.id)"
                              >
                                {{ icons.mdiTrashCanOutline }}
                              </v-icon>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </PerfectScrollbar>
                <div
                  v-else
                  class="d-flex align-center justify-center"
                >
                  <p class="font-weight-semibold py-5 mt-5">
                    Pilih produk terlebih dahulu
                  </p>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-form>
          <div class="d-flex flex-column flex-md-row mt-5">
            <v-menu
              v-if="activeTab === 1"
              :close-on-content-click="false"
              top
              offset-y
            >
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <div v-on="!formData.customer ? tooltip : ''">
                      <v-btn
                        :disabled="!formData.customer"
                        v-bind="attrs"
                        color="primary"
                        :loading="loadingRecommendation"
                        outlined
                        v-on="{ ...tooltip, ...menu }"
                        @click="getRecommendation()"
                      >
                        Dapatkan rekomendasi produk
                      </v-btn>
                    </div>
                  </template>
                  <span v-if="!formData.customer">Input pelanggan terlebih dahulu<br>untuk mendapatkan rekomendasi</span>
                  <span v-else>Dapatkan Rekomendasi dari<br>Produk yang dipilih</span>
                </v-tooltip>
              </template>
              <v-card
                width="500px"
                dense
              >
                <v-list style="height: 500px; overflow-y: scroll;">
                  <v-list-item
                    v-for="rec in recommendationList"
                    :key="rec.id"
                    width="100px"
                  >
                    <v-img
                      :src="'https://images.unsplash.com/photo-1588421357574-87938a86fa28?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'"
                      class="rounded mr-3 my-1 text--success"
                      height="60px"
                      width="60px"
                      max-height="60px"
                      max-width="60px"
                    />
                    <v-list-item-content>
                      <v-list-item-title class="body-2 font-weight-bold primary--text">
                        {{ rec.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption">
                        {{ rec.code_sku ? rec.code_sku : "Tidak ada SKU" }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="caption font-weight-semibold">
                        {{ formatIDR(rec.price) }}
                      </v-list-item-subtitle>
                      <div>
                        <v-chip
                          v-for="type in rec.type"
                          :key="type.id"
                          x-small
                          :color="type.color"
                          class="mr-2 font-weight-bold"
                        >
                          {{ type.name }}
                        </v-chip>
                      </div>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        small
                        text
                        color="primary"
                        @click="addProduct(rec)"
                      >
                        <v-icon>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
            <v-btn
              class="mb-2 mb-md-0 ml-md-auto"
              :style="!updateMode ? {'border-top-right-radius':'0%', 'border-bottom-right-radius':'0%'} : null"
              color="primary"
              :loading="loadingSubmit"
              @click="submitForm()"
            >
              Simpan Job
            </v-btn>
            <v-menu
              v-if="!updateMode"
              v-model="submitMenu"
              offset-y
              top
              transition="slide-y-reverse-transition"
            >
              <template v-slot:activator="menu">
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="tooltip">
                    <v-btn
                      color="primary"
                      outlined
                      class="px-0"
                      style="min-width: 40px;"
                      :style="!updateMode ? {'border-top-left-radius':'0%', 'border-bottom-left-radius':'0%'} : null"
                      v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                      v-on="{ ...menu.on, ...tooltip.on }"
                    >
                      <v-icon
                        class="light--text"
                        size="20"
                      >
                        {{ icons.mdiMenuDown }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Opsi Simpan</span>
                </v-tooltip>
              </template>
              <v-list
                dense
                class="subtitle-2"
              >
                <v-list-item @click="submitForm('detail')">
                  Buat dan Buka
                  <span class="caption ml-auto ps-3">(Alt + Enter)</span>
                </v-list-item>
                <v-list-item @click="submitForm('open')">
                  Buat dan Mulai Lagi
                  <span class="caption ml-auto ps-3">(Ctrl + Enter)</span>
                </v-list-item>
                <v-list-item @click="submitForm('duplicate')">
                  Buat dan Duplikat
                  <span class="caption ml-auto ps-3">(Ctrl + Alt + Enter)</span>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-card-text>
      </v-card>
      <v-overlay
        opacity="0.2"
        :value="loadingDetail"
      >
        <v-progress-circular
          indeterminate
          size="32"
        />
      </v-overlay>
    </v-dialog>

    <JobTypeForm
      ref="typeForm"
      @success="fetchJobType"
    />
    <custom-attribute-form
      ref="customAttributeForm"
      :object-type-id="2"
      @update="$emit('success'); close()"
    />
    <custom-attributes-list
      v-if="formData.id"
      ref="customAttributesList"
      :object-id="formData.id"
      :object-type-id="2"
      :job-type-id="formData.job_type_id"
      @update="$emit('success'); close()"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import { integerValidator, required } from '@core/utils/validation'
import {
  mdiClose,
  mdiDotsHorizontal,
  mdiPlus,
  mdiTrashCanOutline,
  mdiMenuDown,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { isNumber } from '@vueuse/shared'
import { onKeyStroke, useStorage } from '@vueuse/core'
import Counter from '@/components/inputs/Counter.vue'
import CustomerAutoSuggest from '@/components/inputs/CustomerAutoSuggest.vue'
import DateRangePicker from '@/components/inputs/DateRangePicker.vue'
import DateTimePicker from '@/components/inputs/DateTimePicker.vue'
import ProductAutoSuggest from '@/components/inputs/ProductAutoSuggest.vue'
import UserTeamAutoSuggest from '@/components/inputs/UserTeamAutoSuggest.vue'
import useCustomAttributes from '@/composables/useCustomAttributes'
import { addJob, assignObject, updateJob } from '@/graphql/mutations'
import {
  getFolders,
  jobDetail,
  jobPriority,
  jobStatus,
  jobTypes,
  productRecommendations,
} from '@/graphql/queries'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import CustomAttributeForm from '@/views/custom-attributes/CustomAttributeForm.vue'
import CustomAttributesList from '@/views/custom-attributes/CustomAttributesList.vue'
import { apolloClient } from '@/vue-apollo'
import JobTypeForm from './JobTypeForm.vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import FormCkeditor from './FormCkeditor.vue'
import Treeselect from '@riophae/vue-treeselect'
import { useRouter } from '@/@core/utils'

export default {
  components: {
    CustomerAutoSuggest,
    UserTeamAutoSuggest,
    JobTypeForm,
    CustomAttributesList,
    CustomAttributeForm,
    DateTimePicker,
    ProductAutoSuggest,
    DateRangePicker,
    Counter,
    PerfectScrollbar,
    FormCkeditor,
    Treeselect,
  },

  setup(props, { emit }) {
    const jobForm = ref()
    const { router } = useRouter()
    const isOpen = ref(false)
    const formData = ref({
      id: null,
      name: '',
      start_date: null,
      expected_close_date: null,
      description: '',
      job_type_id: null,
      job_status_id: null,
      job_priority_id: null,
      job_product: [],
      customer_id: null,
      assignTo: [],
    })
    const product = ref([])
    const productKey = ref(0)
    const loadingSubmit = ref(false)
    const loadingDetail = ref(false)
    const updateMode = ref(false)
    const showDate = ref(false)
    const maxDate = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    const attributeOption = ref()
    const jobType = ref()
    const status = ref()

    const { customAttributesArray, fetchCustomAttributes } = useCustomAttributes({ objectTypeId: 2 })

    const types = ref([])
    const fetchJobType = () => {
      apolloClient.query({
        query: jobTypes,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        types.value = result.data.jobTypes
      })
    }

    const statusOptions = ref([])
    const priorityOptions = ref([])

    const fetchJobStatus = () => {
      apolloClient.query({
        query: jobStatus,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          job_type: [formData.value.job_type_id],
        },
      }).then(result => {
        statusOptions.value = result.data.jobStatus

        if (status.value) formData.value.job_status_id = status.value
        else if (!updateMode.value) formData.value.job_status_id = result.data.jobStatus[0].id
      })
    }

    const fetchJobPriority = () => {
      apolloClient.query({
        query: jobPriority,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          job_type: [formData.value.job_type_id],
        },
      }).then(result => {
        priorityOptions.value = result.data.jobPriority
      })
    }

    watch(jobType, async () => {
      formData.value.job_type_id = jobType.value

      if (isOpen.value && !updateMode.value) {
        fetchJobStatus()
        fetchJobPriority()
        await fetchCustomAttributes(null, jobType.value)
        if (attributeOption.value) {
          customAttributesArray.value.forEach((item, index) => {
            if (item.custom_attribute.id === attributeOption.value.field) {
              customAttributesArray.value[index].value = attributeOption.value.id
            }
          })
        }
      }
    })

    const recommendationList = ref([])

    const reset = () => {
      updateMode.value = false
      loadingSubmit.value = false
      loadingDetail.value = false
      attributeOption.value = null
      jobType.value = null
      status.value = null
      recommendationList.value = []

      // reset
      formData.value = {
        id: null,
        name: '',
        start_date: null,
        expected_close_date: null,
        description: '',
        job_type_id: null,
        job_status_id: null,
        job_priority_id: null,
        job_product: null,
        customer: null,
        assignTo: [],
      }

      jobForm.value.reset()
      customAttributesArray.value = null
    }

    const restart = () => {
      updateMode.value = false
      loadingSubmit.value = false
      loadingDetail.value = false
      attributeOption.value = null
      status.value = null

      // reset
      formData.value = {
        id: null,
        name: '',
        start_date: null,
        expected_close_date: null,
        description: '',
        job_type_id: jobType.value,
        job_status_id: statusOptions.value[0].id,
        job_priority_id: null,
        job_product: null,
        customer: null,
        assignTo: [],
      }

      jobForm.value.resetValidation()
    }

    const close = () => {
      isOpen.value = false
      reset()
    }

    const folderList = ref(null)
    const fetchFolders = () => {
      apolloClient.query({
        query: getFolders,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          filter: {
            show_archived: false,
            sort: 'name',
            sortType: 'ASC',
          },
        },
      }).then(result => {
        folderList.value = result.data.folders
      }).catch(err => {
        close()
        errorHandling(err)
      })
    }

    const show = (jobTypeId, selectValue) => {
      if (jobTypeId) jobType.value = jobTypeId
      if (selectValue) {
        if (selectValue.field === 'status') status.value = selectValue.id
        else if (selectValue.field === 'priority') formData.value.job_priority_id = selectValue.id
        else attributeOption.value = selectValue
      }
      isOpen.value = true
    }

    const update = id => {
      isOpen.value = true
      loadingDetail.value = true
      apolloClient.query({
        query: jobDetail,
        fetchPolicy: 'no-cache',
        variables: {
          job_id: +id,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        const data = result.data.jobDetail
        formData.value = {
          id,
          name: data.name,
          start_date: data.start_date,
          expected_close_date: data.expected_close_date,
          description: data.description,
          job_status_id: data.status.id,
          job_type_id: data.job_type.id,
          job_priority_id: data.priority?.id,
          customer: data.customer,
          customer_id: data.customer?.id,
          job_product: data.products.map(el => ({
            product_id: el.product.id,
            quantity: el.quantity,
          })),
          assignTo: data.assignedTo.map(el => `user-${el.user.id}`),
        }
        product.value = data.products.map(el => ({
          ...el.product,
          quantity: el.quantity,
        }))
        fetchJobStatus()
        fetchJobPriority()
        fetchCustomAttributes(formData.value.id, formData.value.job_type_id)
        updateMode.value = true
        jobType.value = data.job_type.id
        loadingDetail.value = false
      }).catch(err => {
        errorHandling(err)
        close()
      })
    }

    const loadingRecommendation = ref(false)

    const isType = useStorage('is_type_matrix', false)
    const getRecommendation = () => {
      loadingRecommendation.value = true
      apolloClient.query({
        query: productRecommendations,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          customer_id: formData.value.customer.id,
          is_type_matrix: isType.value,
        },
      }).then(result => {
        loadingRecommendation.value = false
        recommendationList.value = result.data.productRecommendations.products.filter(val => !product.value.map(el => el.id).includes(val.id)).slice(0, 20)
        if (result.data.productRecommendations.count === 0) {
          Vue.notify({
            title: 'Tidak ada rekomendasi produk',
            text: 'Belum cukup data untuk mendapatkan rekomendasi produk',
            type: 'warn',
          })
        }
      }).catch(err => {
        loadingRecommendation.value = false
        errorHandling(err)
      })
    }

    const changeQuantity = ({ id, qty }) => {
      const target = formData.value.job_product.find(val => val.product_id === id)
      target.quantity = qty
      const data = formData.value.job_product.filter(f => f.product_id !== id)
      formData.value.job_product = [...data, target]
    }

    const removeProduct = id => {
      product.value = product.value.filter(val => val.id !== id)
      formData.value.job_product = formData.value.job_product.filter(val => val.product_id !== id)
    }

    const productInput = (data, input) => {
      product.value = data
      formData.value.job_product = data.length ? data.map(val => ({ product_id: val.id, quantity: !input ? 1 : val.quantity })) : null
    }

    const addProduct = data => {
      product.value.push(data)
      formData.value.job_product.push({ product_id: data.id, quantity: 1 })

      // remove from recommendation list
      recommendationList.value.splice(recommendationList.value.findIndex(val => val.id === data.id), 1)
    }

    const submitForm = async action => {
      if (jobForm.value.validate()) {
        const teams = []
        const users = []

        formData.value.assignTo = formData.value.assignTo.map(el => el.id ?? el)

        formData.value.assignTo
        && formData.value.assignTo.length
        && formData.value.assignTo.map(value => {
          if (!isNumber(value)) {
            const ids = value.split('-')
            if (ids[0] === 'team') teams.push(+ids[1])
            if (ids[0] === 'user') users.push(+ids[1])
          }
        })
        loadingSubmit.value = true
        const payloadAttr = customAttributesArray.value.map(item => {
          let val
          if (item.custom_attribute.data_type.id === 3) val = item.value ? new Date(item.value) : null
          else if (item.custom_attribute.data_type.id === 4) val = item.value?.toString() || null
          else if (item.custom_attribute.data_type.id === 5) val = item.value && item.value.length ? JSON.stringify(item.value.sort((a, b) => a - b)) : null
          else val = item.value || null

          return {
            id: item.custom_attribute.id,
            value: val,
          }
        })

        if (!updateMode.value) {
          apolloClient
            .mutate({
              mutation: addJob,
              variables: {
                ...formData.value,
                start_date: new Date(Date.parse(formData.value.start_date)),
                expected_close_date: new Date(Date.parse(formData.value.expected_close_date)),
                assign_user_id: users.length ? users : null,
                assign_team_id: teams.length ? teams : null,
                workspace_id: store.getters.getCurrentWorkspaceId,
                custom_attribute: payloadAttr,
              },
            })
            .then(async ({ data }) => {
              loadingSubmit.value = false
              emit('success')
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil membuat job!',
              })
              if (action === 'detail') router.push({ name: 'job-detail', params: { id: JSON.parse(data.addJob.data).id } })
              else if (action === 'open') restart()
              else if (action !== 'duplicate') close()
            })
            .catch(err => {
              errorHandling(err)
              loadingSubmit.value = false
            })
        } else {
          apolloClient.mutate({
            mutation: updateJob,
            variables: {
              ...formData.value,
              start_date: new Date(Date.parse(formData.value.start_date)),
              expected_close_date: new Date(Date.parse(formData.value.expected_close_date)),
              workspace_id: store.getters.getCurrentWorkspaceId,
              custom_attribute: payloadAttr,
            },
          }).then(async () => {
            loadingSubmit.value = false
            await apolloClient.mutate({
              mutation: assignObject,
              variables: {
                assign_user_id: users.length ? users : null,
                assign_team_id: teams.length ? teams : null,
                job_id: formData.value.id,
              },
            }).then(() => {
              emit('success')
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil mengubah job!',
              })
              close()
            }).catch(err => {
              errorHandling(err)
            })
          }).catch(err => {
            loadingSubmit.value = false
            errorHandling(err)
          })
        }
      }
    }

    const setDateRange = event => {
      formData.value.start_date = event.startDate
      formData.value.expected_close_date = event.endDate
    }

    const normalizer = node => ({
      id: node.id,
      label: node.name,
      children: node.job_types,
    })

    onMounted(() => {
      fetchFolders()
    })

    const activeTab = ref(0)
    const submitMenu = ref(null)

    onKeyStroke('Enter', e => {
      if (isOpen.value) {
        if (e.ctrlKey && e.altKey) submitForm('duplicate')
        else if (e.ctrlKey) submitForm('open')
        else if (e.altKey) submitForm('detail')
        else submitForm()
      }
    })

    const formatIDR = number => new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
    }).format(number)

    return {
      submitMenu,
      jobForm,
      isOpen,
      formData,
      loadingSubmit,
      loadingDetail,
      updateMode,
      showDate,
      maxDate,
      types,
      customAttributesArray,
      jobType,
      product,
      productKey,
      statusOptions,
      priorityOptions,
      activeTab,
      folderList,

      show,
      close,
      reset,
      update,
      submitForm,
      fetchJobType,
      required,
      integerValidator,
      productInput,
      changeQuantity,
      removeProduct,
      fetchCustomAttributes,
      setDateRange,
      normalizer,
      formatIDR,

      loadingRecommendation,
      recommendationList,
      getRecommendation,
      addProduct,

      icons: {
        mdiClose,
        mdiDotsHorizontal,
        mdiPlus,
        mdiTrashCanOutline,
        mdiMenuDown,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~@/styles/treeselect.scss';

.job-form-content {
  @include style-scroll-bar();
  padding-right: 12px !important;
  overflow-x: hidden;
  overflow-y: scroll !important;
}
.custom-attribute-scroll-bar {
  padding-right: 12px !important;
  margin-right: -12px !important;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
}
.product-scroll-bar {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
}
</style>
