import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"mb-2",attrs:{"color":_vm.outlined ? 'primary' : null,"rounded":"","outlined":_vm.outlined}},[_c(VCard,{staticClass:"d-flex py-3 px-1 justify-space-between",attrs:{"flat":"","outlined":""},on:{"click":function($event){return _vm.$emit('change')}}},[_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"mr-2",attrs:{"size":"36"}},[_c(VImg,{attrs:{"src":_vm.data.workspace.photo ? _vm.data.workspace.photo : 'https://dummyimage.com/200x200/EDF2FE/6185F6.png?text=Oriens'}})],1),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-subtitle-2 me-2 text-truncate",staticStyle:{"max-width":"180px"}},[_vm._v(_vm._s(_vm.data.workspace.name))]),_c('span',{staticClass:"text-caption text--disabled"},[_vm._v(_vm._s(_vm.data.role.name))])])],1),_c('div',{staticClass:"d-flex"},[(_vm.isMyWorkspace)?_c(VChip,{staticClass:"py-3 px-2",attrs:{"x-small":"","color":"primary","outlined":"","label":""}},[_vm._v(" Milik Saya ")]):_vm._e(),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"plain":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}])},[_c(VList,[(_vm.WorkspacePermissionGuard(_vm.data, 1))?_c(VListItem,{on:{"click":function($event){return _vm.$emit('detail')}}},[_c(VListItemIcon,{staticClass:"mr-1"},[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCog)+" ")])],1),_c(VListItemTitle,[_vm._v("Pengaturan")])],1):_vm._e(),(!_vm.isMyWorkspace)?_c(VDivider,{staticClass:"my-2"}):_vm._e(),(!_vm.isMyWorkspace)?_c(VListItem,{on:{"click":function($event){return _vm.$emit('detail')}}},[_c(VListItemIcon,{staticClass:"mr-1"},[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExitToApp)+" ")])],1),_c(VListItemTitle,[_vm._v("Keluar dari Workspace")])],1):_vm._e()],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }