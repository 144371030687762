<template>
  <v-dialog
    v-model="isOpen"
    scrollable
    max-width="1200"
  >
    <v-card height="80vh">
      <v-navigation-drawer
        v-model="isLeftSidebarOpen"
        width="260"
        touchless
        mobile-breakpoint="sm"
        :temporary="$vuetify.breakpoint.smAndDown"
        absolute
        class="left-sidebar"
      >
        <div class="d-flex flex-column h-full py-4 px-2">
          <div class="d-flex flex-column mx-2 mb-1">
            <span class="text-caption text--disabled">Workspace</span>
            <span class="text-subtitle-2">{{ workspace.name }}</span>
          </div>
          <v-divider class="my-2" />
          <v-list
            rounded
            dense
          >
            <v-list-item-group
              v-model="selectedItem"
              color="primary"
              mandatory
            >
              <v-list-item
                v-for="(menu, index) in filteredWorkspaceMenu"
                :key="index"
                @click="
                  isLeftSidebarOpen && $vuetify.breakpoint.smAndDown ? (isLeftSidebarOpen = !isLeftSidebarOpen) : null
                "
              >
                <v-list-item-icon>
                  <v-icon>{{ menu.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ menu.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-card
            outlined
            class="pa-2 mx-2 mt-auto d-flex flex-column"
          >
            <div class="d-flex align-start mb-1">
              <div class="d-flex flex-column">
                <span class="text-caption text--disabled">Paket Berlangganan</span>
                <span class="text-subtitle-2">Personal</span>
              </div>
              <v-icon
                class="ml-auto"
                color="primary"
                size="20"
              >
                {{ icons.mdiHelpCircleOutline }}
              </v-icon>
            </div>
            <span class="text-caption mb-2">Dapatkan manfaat lebih dari Oriens CRM dengan berlangganan</span>
            <v-btn
              outlined
              small
              color="primary"
            >
              Upgrade
            </v-btn>
          </v-card>
          <v-btn
            outlined
            small
            text
            color="error"
            class="mt-2 mx-2"
            @click="quit"
          >
            Keluar Workspace
          </v-btn>
        </div>
      </v-navigation-drawer>

      <div
        :style="{
          'margin-left': isLeftSidebarOpen && $vuetify.breakpoint.mdAndUp ? '260px' : null,
        }"
        class="main-area"
      >
        <div class="w-full">
          <div class="d-flex mt-6 mb-4">
            <v-icon
              class="ml-4"
              @click="isLeftSidebarOpen = !isLeftSidebarOpen"
            >
              {{ isLeftSidebarOpen ? icons.mdiChevronDoubleLeft : icons.mdiChevronDoubleRight }}
            </v-icon>
            <h3
              class="mx-auto"
              :class="{ 'text-subtitle-2 font-weight-bold' : $vuetify.breakpoint.width < 500 }"
            >
              {{ title }}
            </h3>
            <v-icon
              class=" mr-6"
              @click="isOpen = !isOpen"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </div>
          <v-tabs-items
            v-if="!loadingDetail"
            v-model="selectedItem"
            touchless
          >
            <v-tab-item>
              <WorkspaceSetting
                :workspace="workspace"
                @onDelete="isOpen = false"
              />
            </v-tab-item>
            <v-tab-item>
              <WorkspaceSharedUser
                :workspace="workspace"
                @refetch="fetchWorkspaceDetail"
              />
            </v-tab-item>
            <!-- <v-tab-item>
              <Role
                :id="workspaceId"
                :workspace="workspace"
              />
            </v-tab-item> -->
            <v-tab-item>
              <WorkspaceChatSettings
                :workspace="workspace"
              />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </v-card>
    <v-overlay
      opacity="0.2"
      :value="loadingDetail"
    >
      <v-progress-circular
        indeterminate
        size="32"
      />
    </v-overlay>
  </v-dialog>
</template>

<script>
import {
  ref, watchEffect, computed,
} from '@vue/composition-api'
import {
  mdiClose, mdiAccountMultiplePlusOutline, mdiMenu,
  mdiCog,
  // mdiAccountKey,
  mdiAccountGroup,
  mdiForum,
  mdiChevronDoubleRight,
  mdiChevronDoubleLeft,
  mdiHelpCircleOutline,
} from '@mdi/js'
import { useStorage } from '@vueuse/core'
import Vue from 'vue'
import { getVuetify } from '@/@core/utils'
import errorHandling from '@/utils/errorHandling'
import WorkspaceSharedUser from '@/views/workspace/WorkspaceSharedUser.vue'
import WorkspaceSetting from '@/views/workspace/WorkspaceSetting.vue'
import WorkspaceChatSettings from './WorkspaceChatSettings.vue'
// import Role from '@/views/workspace/role/Role.vue'
import { apolloClient } from '@/vue-apollo'
import { workspaceDetail } from '@/graphql/queries'
import store from '@/store'
import { quitWorkspace } from '@/graphql/mutations'
import router from '@/router'
// import ability from '@/plugins/acl/ability'

export default {
  components: {
    WorkspaceSharedUser,
    WorkspaceSetting,
    WorkspaceChatSettings,
    // Role,
  },
  setup() {
    const loadingQuit = ref(false)
    const isOpen = ref(false)
    const loadingDetail = ref(false)
    const workspaceId = ref(0)
    const workspace = ref({})
    const isLeftSidebarOpen = ref(true)
    const selectedItem = useStorage('workspaceTabItem', 0)
    const $vuetify = getVuetify()

    const workspaceMenu = [
      {
        icon: mdiCog,
        title: 'Pengaturan Workspace',
        hasPermission: true,
      },
      {
        icon: mdiAccountGroup,
        title: 'Daftar Pengguna',
        hasPermission: true,
      },
      // {
      //   icon: mdiAccountKey,
      //   title: 'Manajemen Role',
      //   hasPermission: true,
      // },
      {
        icon: mdiForum,
        title: 'Pengaturan Chat',
        hasPermission: true,
      },
    ]

    const filteredWorkspaceMenu = computed(() => workspaceMenu.filter(menu => menu.hasPermission))

    const title = computed(() => {
      if (selectedItem.value <= filteredWorkspaceMenu.value.length - 1) {
        return filteredWorkspaceMenu.value[selectedItem.value].title
      }

      return ''
    })

    watchEffect(() => {
      if ($vuetify.breakpoint.mdAndUp) isLeftSidebarOpen.value = true
      else isLeftSidebarOpen.value = false
    })

    const fetchWorkspaceDetail = () => {
      loadingDetail.value = true
      apolloClient.query({
        query: workspaceDetail,
        variables: {
          workspace_id: workspaceId.value,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        workspace.value = result.data.workspaceDetail
        loadingDetail.value = false
        isLeftSidebarOpen.value = true
      }).catch(err => {
        loadingDetail.value = false
        isOpen.value = false
        errorHandling(err)
      })
    }
    const show = id => {
      workspaceId.value = id
      isOpen.value = true
      fetchWorkspaceDetail()
    }
    const close = () => {
      isOpen.value = false
    }
    const quit = () => {
      Vue.$dialog({
        title: 'Keluar Workspace?',
        body: 'Yakin ingin keluar dari workspace?',
      }).then(result => {
        if (result) {
          loadingQuit.value = true
          apolloClient.mutate({
            mutation: quitWorkspace,
            variables: {
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            loadingQuit.value = false
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil keluar dari workspace!',
            })
            router.go()
          }).catch(err => {
            loadingQuit.value = false
            errorHandling(err)
          })
        }
      })
    }

    return {
      workspaceMenu,
      filteredWorkspaceMenu,
      title,
      workspace,
      workspaceId,
      isOpen,
      loadingDetail,
      isLeftSidebarOpen,
      selectedItem,
      show,
      close,
      quit,
      fetchWorkspaceDetail,
      icons: {
        mdiClose,
        mdiAccountMultiplePlusOutline,
        mdiMenu,
        mdiChevronDoubleRight,
        mdiChevronDoubleLeft,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.main-area {
  @include style-scroll-bar();
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
