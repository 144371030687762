import '@/plugins/vue-composition-api'
import '@/plugins/ej2-grids'
import '@/plugins/ej2-gantt'
import '@/styles/styles.scss'
import 'vue-img-viewr/styles/index.css'
import Vue from 'vue'
import VueAnimXYZ from '@animxyz/vue'
import '@animxyz/core'
import Notifications from 'vue-notification'
import { abilitiesPlugin } from '@casl/vue'
import ability from './plugins/acl/ability'
import { apolloProvider } from '@/vue-apollo'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import App from './App.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { registerLicense } from '@syncfusion/ej2-base'
import CKEditor from '@ckeditor/ckeditor5-vue2'

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Xd0RjWntXcndVRmVa')
Vue.use(CKEditor)
Vue.use(VueAnimXYZ)
Vue.use(Notifications)
Vue.config.productionTip = false
Vue.use(abilitiesPlugin, ability)

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
