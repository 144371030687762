import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VMenu,{attrs:{"bottom":"","right":"","offset-y":"","nudge-bottom":8},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"28"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}])},[_c(VCard,[_c(VList,{attrs:{"nav":""}},_vm._l((_vm.quickAddItems),function(item,i){return _c('div',{key:i},[(_vm.$can('create', item.name))?_c(VListItem,{key:i,staticClass:"d-flex pl-4 pr-6",on:{"click":item.action}},[_c(VIcon,{staticClass:"mr-4"},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(item.title)+" ")])],1):_vm._e()],1)}),0)],1)],1),_c('customer-form',{ref:"customerForm"}),_c('product-form',{ref:"productForm"}),_c('job-form',{ref:"jobForm"}),_c('prospect-form',{ref:"prospectForm"}),_c('task-form',{ref:"taskForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }