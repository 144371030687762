import { getField, setField } from 'vuex-use-fields'
import { apolloClient } from '@/vue-apollo'

export default {
  namespaced: true,
  state: {
    productList: [],
    loadingProduct: [],
    productFilter: {
      count: 0,
      pagination: {},
      search: '',
      sort: {},
      price: {
        min: null,
        max: null,
      },
      stock: {
        min: null,
        max: null,
      },
      type: [],
    },
  },
  getters: {
    getField,
  },
  mutations: {
    setField,
  },
  actions: {
    getProducts() {
      apolloClient.query()
    },
  },
}
