<template>
  <ckeditor
    v-model="editorData"
    :editor="editor"
    :config="editorConfig"
  />
</template>

<script>
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment'
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import CodePlugin from '@ckeditor/ckeditor5-basic-styles/src/code'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import FontPlugin from '@ckeditor/ckeditor5-font/src/font'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import HighlightPlugin from '@ckeditor/ckeditor5-highlight/src/highlight'
import HorizontalLinePlugin from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import IndentPlugin from '@ckeditor/ckeditor5-indent/src/indent'
import ListPlugin from '@ckeditor/ckeditor5-list/src/list'
import TodoListPlugin from '@ckeditor/ckeditor5-list/src/todolist'
import MediaEmbedPlugin from '@ckeditor/ckeditor5-media-embed/src/mediaembed'
import PageBreakPlugin from '@ckeditor/ckeditor5-page-break/src/pagebreak'
import PasteFromOfficePlugin from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import RemoveFormatPlugin from '@ckeditor/ckeditor5-remove-format/src/removeformat'
import StandardEditingPlugin from '@ckeditor/ckeditor5-restricted-editing/src/standardeditingmode'
import SpecialCharactersPlugin from '@ckeditor/ckeditor5-special-characters/src/specialcharacters'
import TablePlugin from '@ckeditor/ckeditor5-table/src/table'
import UploadPlugin from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter'
import WordCountPlugin from '@ckeditor/ckeditor5-word-count/src/wordcount'
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials'

export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        placeholder: 'Masukkan Deskripsi Job',
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          AlignmentPlugin,
          AutoformatPlugin,
          BlockQuotePlugin,
          FontPlugin,
          HeadingPlugin,
          HighlightPlugin,
          HorizontalLinePlugin,
          ImagePlugin,
          IndentPlugin,
          ListPlugin,
          MediaEmbedPlugin,
          PageBreakPlugin,
          PasteFromOfficePlugin,
          RemoveFormatPlugin,
          StandardEditingPlugin,
          SpecialCharactersPlugin,
          SpecialCharactersEssentials,
          TablePlugin,
          UploadPlugin,
          WordCountPlugin,
          StrikethroughPlugin,
          UnderlinePlugin,
          SubscriptPlugin,
          SuperscriptPlugin,
          CodePlugin,
          TodoListPlugin,
        ],
        toolbar: {
          items: [
            // /* 'findAndReplace', */ 'selectAll', '|',
            'heading', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', 'removeFormat', '|',
            'alignment', 'bulletedList', 'numberedList', 'todoList', '|',
            'outdent', 'indent', '|',
            'undo', 'redo',
            '-',
            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
            'link', /* 'insertImage', */ 'blockQuote', 'insertTable', 'mediaEmbed', '|',
            'specialCharacters', 'horizontalLine', 'pageBreak', '|',
            // 'textPartLanguage', '|',
            // 'sourceEditing',
          ],
          shouldNotGroupWhenFull: true,
        },
      },
    }
  },
  computed: {
    editorData: {
      get() {
        return this.content
      },
      set(value) {
        this.$emit('update:content', value)
      },
    },
  },
}
</script>

<style lang="scss">
@import '~@/styles/ckeditor-lark.scss';

.ck-editor__editable_inline {
  max-height: 400px;
}
</style>
