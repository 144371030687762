import Vue from 'vue'
import { apolloClient } from '@/vue-apollo'
import {
  addUserCard,
  changeAutoSubscribe,
  deleteUser, deleteUserCard, downgradePlanSubscription, toggleBanUser, verifyAccount,
} from '@/graphql/mutations'
import { ref } from '@vue/composition-api'
import { getAllUsers, getUserByVerificationCode, userCards } from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'

const useUser = () => {
  const userDetail = ref({})
  const userList = ref([])
  const userCardList = ref([])
  const userCount = ref(0)

  const loadingListUser = ref(false)
  const loadingDetailUser = ref(false)
  const loadingVerifyUser = ref(false)
  const loadingChangeBanUser = ref(false)
  const loadingDowngradeUser = ref(false)
  const loadingCardUser = ref(false)
  const loadingDeleteCardUser = ref(false)
  const loadingAddCardUser = ref(false)
  const loadingChangeAutoSubscribe = ref(false)

  const userStatusColor = id => {
    if (id === 1) return 'error'
    if (id === 2) return 'primary'

    return 'secondary'
  }

  const delUser = id => new Promise((resolve, reject) => {
    Vue.$dialog({
      title: 'Hapus pengguna?',
      body: 'Konfirmasi jika anda ingin menghapus pengguna.',
    }).then(confirm => {
      if (confirm) {
        apolloClient.mutate({
          mutation: deleteUser,
          variables: {
            id,
          },
        }).then(result => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil menghapus pengguna!',
          })
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      } else {
        reject()
      }
    })
  })

  const fetchAllUsers = (pagination, search, filter) => {
    loadingListUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getAllUsers,
        variables: {
          pagination,
          search,
          filter,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingListUser.value = false
        userList.value = result.data.getAllUsers.users
        userCount.value = result.data.getAllUsers.count
        resolve(result.data.getUsers)
      }).catch(err => {
        loadingListUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const changeBanUser = (id, value) => {
    loadingChangeBanUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: toggleBanUser,
        variables: {
          user_id: id,
          value,
        },
      }).then(result => {
        loadingChangeBanUser.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah status pengguna!',
        })
        resolve(result)
      }).catch(err => {
        loadingChangeBanUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchUserByVerificationCode = verificationCode => {
    loadingDetailUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getUserByVerificationCode,
        variables: {
          verification_code: verificationCode,
        },
      }).then(({ data }) => {
        loadingDetailUser.value = false
        userDetail.value = data.getUserByVerificationCode
        resolve(data.getUserByVerificationCode)
      }).catch(err => {
        loadingDetailUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const verifyUser = (verificationCode, password, confirmPassword) => {
    loadingVerifyUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: verifyAccount,
        variables: {
          verification_code: verificationCode,
          password,
          confirm_password: confirmPassword,
        },
      }).then(result => {
        loadingVerifyUser.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil memverifikasi akun!',
        })
        resolve(result)
      }).catch(err => {
        loadingVerifyUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const downgradeUserPlan = subscriptionId => {
    loadingDowngradeUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: downgradePlanSubscription,
        variables: {
          subscription_id: subscriptionId,
        },
      }).then(result => {
        loadingDowngradeUser.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil downgrade akun!',
        })
        resolve(result)
      }).catch(err => {
        loadingDowngradeUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const getUserCards = () => {
    loadingCardUser.value = true
    console.log('tes')

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: userCards,
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingCardUser.value = false
        userCardList.value = data.userCards
        console.log(userCardList.value)
        resolve(data.userCards)
      }).catch(err => {
        loadingCardUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const deleteCard = cardId => {
    loadingDeleteCardUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: deleteUserCard,
        variables: {
          card_id: cardId,
        },
      }).then(result => {
        loadingDeleteCardUser.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil menghapus data kartu!',
        })
        resolve(result)
      }).catch(err => {
        loadingDeleteCardUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const addCardData = cardData => {
    loadingAddCardUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: addUserCard,
        variables: {
          param: cardData,
        },
      }).then(result => {
        loadingAddCardUser.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil menambahkan data kartu!',
        })
        resolve(result)
      }).catch(err => {
        loadingAddCardUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const updateAutoSubscribe = value => {
    loadingChangeAutoSubscribe.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: changeAutoSubscribe,
        variables: {
          value,
        },
      }).then(result => {
        loadingChangeAutoSubscribe.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah status auto subscribe!',
        })
        resolve(result)
      }).catch(err => {
        loadingChangeAutoSubscribe.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  return {
    userStatusColor,
    delUser,

    fetchAllUsers,
    userList,
    userCount,
    loadingListUser,
    changeBanUser,
    loadingChangeBanUser,

    verifyUser,
    loadingVerifyUser,

    fetchUserByVerificationCode,
    loadingDetailUser,
    userDetail,

    downgradeUserPlan,
    loadingDowngradeUser,

    getUserCards,
    userCardList,
    loadingCardUser,

    deleteCard,
    loadingDeleteCardUser,

    addCardData,
    loadingAddCardUser,

    updateAutoSubscribe,
    loadingChangeAutoSubscribe,
  }
}

export default useUser
