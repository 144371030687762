<template>
  <v-dialog
    v-model="isOpen"
    max-width="880"
    content-class="job-type-form-content"
    @input="!$event ? reset() : null"
  >
    <v-card
      class="pa-4"
    >
      <v-form
        ref="typeForm"
        lazy-validation
        @submit.prevent="submit"
      >
        <div class="d-flex justify-space-between mb-4">
          <span class="text-h6 font-medium primary--text">Job Type</span>
          <v-icon
            @click="reset(); isOpen = false"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </div>
        <v-row class="mb-2">
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="formData.name"
              :rules="[required]"
              label="Nama Job Type (wajib diisi)"
              placeholder="Input nama job type"
              dense
              outlined
              flat
              hide-details="auto"
              @keydown="$event.keyCode === 13 ? submit() : null"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <color-picker
              v-model="formData.color"
              label="Warna"
              outlined
              :rules="[required]"
            />
          </v-col>
        </v-row>
        <v-card
          flat
          outlined
          class="mt-2"
        >
          <v-tabs
            v-model="activeTab"
            grow
            flat
            class="job-type-form-tabs"
            style="box-shadow: none !important;"
          >
            <v-tab>
              Status
            </v-tab>
            <v-tab>
              Prioritas
            </v-tab>
            <v-tab>
              Atribut Kustom
            </v-tab>
            <v-tab v-if="!updateMode">
              Sharing & Permission
            </v-tab>
            <v-tab-item eager>
              <job-status-list
                ref="jobStatusList"
                :data="formData"
                :mode="updateMode"
                @success="($emit('success'), update({ id: formData.id, name: formData.name }))"
              />
            </v-tab-item>
            <v-tab-item eager>
              <job-priority-list
                ref="jobPriorityList"
                :data="formData"
                :mode="updateMode"
                @success="($emit('success'), update({ id: formData.id, name: formData.name }))"
              />
            </v-tab-item>
            <v-tab-item eager>
              <job-custom-attribute-list
                ref="jobCustomAttributeList"
                :data="formData"
                :mode="updateMode"
                @success="($emit('success'), update({ id: formData.id, name: formData.name }))"
              />
            </v-tab-item>
            <v-tab-item
              v-if="!updateMode"
              eager
            >
              <add-job-type-permission-form
                ref="editJobTypePermissionForm"
                :data="formData"
              />
            </v-tab-item>
          </v-tabs>
        </v-card>

        <v-card-actions class="d-flex mt-4 pa-0">
          <v-btn
            v-if="$can('del', 'Product')"
            color="primary"
            class="ml-auto"
            :loading="loadingAdd"
            @click="submit"
          >
            Simpan {{ updateMode ? tabs[activeTab] : 'Job Type' }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  addJobType,
} from '@/graphql/mutations'
import { customAttributeList, jobPriority, jobStatus } from '@/graphql/queries'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { required } from '@core/utils/validation'
import {
  mdiCloseCircleOutline,
  mdiClose,
  mdiDrag,
  mdiPlus,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import Vue from 'vue'
import JobPriorityList from './JobPriorityList.vue'
import JobStatusList from './JobStatusList.vue'
import JobCustomAttributeList from './JobCustomAttributeList.vue'
import AddJobTypePermissionForm from '@/views/job/components/AddJobTypePermissionForm.vue'
import ColorPicker from '@/components/inputs/ColorPicker.vue'

export default {
  components: {
    JobStatusList,
    JobPriorityList,
    JobCustomAttributeList,
    AddJobTypePermissionForm,
    ColorPicker,
  },
  setup(props, { emit }) {
    const typeForm = ref(null)
    const isOpen = ref(false)
    const updateMode = ref(false)
    const activeTab = ref(0)
    const tabs = ref(['Status', 'Prioritas', 'Atribut'])
    const jobStatusList = ref(null)
    const jobPriorityList = ref(null)
    const jobCustomAttributeList = ref(null)
    const editJobTypePermissionForm = ref(null)
    const formData = ref({
      id: null,
      name: '',
      color: '',
      status: {
        aktif: [
          {
            name: 'Belum Diproses',
            color: '#ff4c51',
            type: 'Aktif',
          },
          {
            name: 'Sedang Diproses',
            color: '#ffb400',
            type: 'Aktif',
          },
        ],
        nonaktif: [],
        tutup: [
          {
            name: 'Selesai Diproses',
            color: '#56ca00',
            type: 'Tutup',
          },
        ],
      },
      priority: [
        {
          name: 'Rendah',
          color: '#6285f6',
          urutan: 1,
        },
        {
          name: 'Menengah',
          color: '#ffb400',
          urutan: 2,
        },
        {
          name: 'Tinggi',
          color: '#ff4c51',
          urutan: 3,
        },
      ],
      customAttributes: [],
      folder_id: null,
      user_permissions: [],
      isPublic: false,
    })
    const loadingAdd = ref(false)

    const show = () => {
      isOpen.value = true
    }

    const add = id => {
      isOpen.value = true
      updateMode.value = false
      formData.value.folder_id = +id
    }

    const update = async data => {
      updateMode.value = true
      await apolloClient.query({
        query: jobStatus,
        fetchPolicy: 'no-cache',
        variables: {
          job_type: [data.id],
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        Object.assign(formData.value, {
          id: data.id,
          name: data.name,
          color: data.color,
          status: {
            aktif: result.data.jobStatus.filter(el => el.type === 'Aktif'),
            nonaktif: result.data.jobStatus.filter(el => el.type === 'Nonaktif'),
            tutup: result.data.jobStatus.filter(el => el.type === 'Tutup'),
          },
        })
      }).catch(err => {
        errorHandling(err)
      })
      await apolloClient.query({
        query: jobPriority,
        fetchPolicy: 'no-cache',
        variables: {
          job_type: data.id,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        Object.assign(formData.value, { priority: result.data.jobPriority })
      }).catch(err => {
        errorHandling(err)
      })
      await apolloClient.query({
        query: customAttributeList,
        fetchPolicy: 'no-cache',
        variables: {
          object_type_id: 2,
          workspace_id: store.getters.getCurrentWorkspaceId,
          job_type_id: data.id,
        },
      }).then(result => {
        Object.assign(formData.value, { customAttributes: result.data.customAttributeList })
        isOpen.value = true
      }).catch(err => {
        errorHandling(err)
      })
    }

    const submit = () => {
      const jobPriorityData = formData.value.priority.map((el, index) => ({ ...el, urutan: index + 1 }))
      const jobStatusData = [
        ...formData.value.status.aktif.map((el, index) => ({ ...el, type: 'Aktif', urutan: index + 1 })),
        ...formData.value.status.nonaktif.map((el, index) => ({ ...el, type: 'Nonaktif', urutan: index + formData.value.status.aktif.length + 1 })),
        ...formData.value.status.tutup.map((el, index) => ({ ...el, type: 'Tutup', urutan: index + formData.value.status.aktif.length + formData.value.status.nonaktif.length + 1 })),
      ]
      const jobCustomAttributeData = formData.value.customAttributes.map((el, index) => ({
        id: el.id, name: el.name, options: el.options.map(opt => ({ id: opt.id, name: opt.name })), order: index + 1, data_type_id: el.data_type.id,
      }))

      if (typeForm.value.validate()) {
        loadingAdd.value = true
        if (!updateMode.value) {
          apolloClient.mutate({
            mutation: addJobType,
            variables: {
              name: formData.value.name,
              folder_id: +formData.value.folder_id,
              job_status: jobStatusData,
              job_priority: jobPriorityData,
              job_custom_attribute: jobCustomAttributeData,
              user_permissions: formData.value.user_permissions,
              is_public: formData.value.isPublic,
              color: formData.value.color,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(result => {
            emit('success', result)
            loadingAdd.value = false
            isOpen.value = false
            Vue.notify({
              title: 'Sukses',
              text: 'Berhasil menambahkan job type baru!',
            })
          }).catch(err => {
            errorHandling(err)
            loadingAdd.value = false
          })
        } else if (activeTab.value === 0) {
          jobStatusList.value.submit(jobStatusData)
          loadingAdd.value = false
        } else if (activeTab.value === 1) {
          jobPriorityList.value.submit(jobPriorityData)
          loadingAdd.value = false
        } else if (activeTab.value === 2) {
          jobCustomAttributeList.value.submit(jobCustomAttributeData)
          loadingAdd.value = false
        }
      }
    }

    const reset = () => {
      updateMode.value = false
      formData.value = {
        id: null,
        name: '',
        status: {
          aktif: [
            {
              name: 'Belum Diproses',
              color: '#ff4c51',
              type: 'Aktif',
            },
            {
              name: 'Sedang Diproses',
              color: '#ffb400',
              type: 'Aktif',
            },
          ],
          nonaktif: [],
          tutup: [
            {
              name: 'Selesai Diproses',
              color: '#56ca00',
              type: 'Tutup',
            },
          ],
        },
        priority: [
          {
            name: 'Rendah',
            color: '#6285f6',
            urutan: 1,
          },
          {
            name: 'Menengah',
            color: '#ffb400',
            urutan: 2,
          },
          {
            name: 'Tinggi',
            color: '#ff4c51',
            urutan: 3,
          },
        ],
        customAttributes: [],
      }

      jobStatusList.value.reset()
      jobPriorityList.value.reset()
      jobCustomAttributeList.value.reset()
      typeForm.value.resetValidation()
    }

    return {
      tabs,
      isOpen,
      activeTab,
      updateMode,
      typeForm,
      formData,
      loadingAdd,
      jobStatusList,
      jobPriorityList,
      jobCustomAttributeList,

      add,
      show,
      reset,
      update,
      submit,
      required,

      icons: {
        mdiClose,
        mdiCloseCircleOutline,
        mdiPlus,
        mdiDrag,
      },

      editJobTypePermissionForm,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.ghost-card {
  background: rgb(240, 240, 240) !important;
}
.job-type-form-content {
  @include style-scroll-bar();
  overflow-x: hidden;
  overflow-y: auto !important;
}
.job-type-form-tabs .v-input__slot::before {
  border: none !important;
}
</style>
