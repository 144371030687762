import gql from 'graphql-tag'

const jobs = gql`
  query jobs(
    $pagination: pagination
    $filter: paramQueryJobs
    $workspace_id: Float!
  ) {
    jobs(
      pagination: $pagination
      filter: $filter
      workspace_id: $workspace_id
    ) {
      count
      jobs {
        id
        name
        description
        strip_description
        job_type {
          id
          name
        }
        tasks {
          id
          subject
          description
          start_date
          end_date
          completed_at
          lexorank
          job {
            id
          }
          assignedTo {
            id
            user {
              id
              name
              photo
            }
          }
          created_by {
            id
            name
          }
        }
        status {
          id
          name
          color
          urutan
        }
        priority {
          id
          name
          color
          urutan
        }
        start_date
        expected_close_date
        customer {
          id
          name
        }
        created_by {
          id
          first_name
          last_name
          name
        }
        created_at
        updated_at
        deleted_at
        assignedTo {
          id
          user {
            id
            phone
            email
            name
            first_name
            last_name
            photo
          }
        }
        lexorank
        custom_attribute_values
      }
    }
  }
`

export default jobs
