import gql from 'graphql-tag'

const getWorkflowLog = gql`
  query getWorkflowLog(
    $workspace_id: Float!
    $pagination: pagination!
    $filter: paramWorkflowLog!
  ) {
    getWorkflowLog(
      workspace_id: $workspace_id
      pagination: $pagination
      filter: $filter
    ) {
      count
      logs {
        id
        executed_at
        is_error
        message
        action_name
        object_name
      }
    }
  }
`

export default getWorkflowLog
