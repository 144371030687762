<template>
  <v-sheet
    :color="outlined ? 'primary' : null"
    rounded
    :outlined="outlined"
    class="mb-2"
  >
    <v-card
      flat
      outlined
      class="d-flex py-3 px-1 justify-space-between"
      @click="$emit('change')"
    >
      <div class="d-flex align-center">
        <v-avatar
          size="36"
          class="mr-2"
        >
          <v-img
            :src="data.workspace.photo ? data.workspace.photo : 'https://dummyimage.com/200x200/EDF2FE/6185F6.png?text=Oriens'"
          />
        </v-avatar>
        <div class="d-flex flex-column">
          <span
            class="text-subtitle-2 me-2 text-truncate"
            style="max-width: 180px;"
          >{{ data.workspace.name }}</span>

          <span class="text-caption text--disabled">{{ data.role.name }}</span>
        </div>
      </div>
      <div class="d-flex">
        <v-chip
          v-if="isMyWorkspace"
          x-small
          color="primary"
          outlined
          label
          class="py-3 px-2"
        >
          Milik Saya
        </v-chip>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              plain
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="WorkspacePermissionGuard(data, 1)"
              @click="$emit('detail')"
            >
              <v-list-item-icon class="mr-1">
                <v-icon size="20">
                  {{ icons.mdiCog }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Pengaturan</v-list-item-title>
            </v-list-item>
            <v-divider
              v-if="!isMyWorkspace"
              class="my-2"
            />
            <v-list-item
              v-if="!isMyWorkspace"
              @click="$emit('detail')"
            >
              <v-list-item-icon class="mr-1">
                <v-icon size="20">
                  {{ icons.mdiExitToApp }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Keluar dari Workspace</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-card>
  </v-sheet>
</template>

<script>
import { computed } from '@vue/composition-api'
import { mdiDotsVertical, mdiCog, mdiExitToApp } from '@mdi/js'
import store from '@/store'
import useWorkspace from '@/composables/useWorkspace'

export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const userData = computed(() => store.state.auth.userData)
    const isMyWorkspace = computed(() => props.data.workspace.owner.id === userData.value.id)
    const { WorkspacePermissionGuard } = useWorkspace()

    return {
      isMyWorkspace,

      icons: {
        mdiDotsVertical,
        mdiCog,
        mdiExitToApp,
      },
      WorkspacePermissionGuard,
    }
  },
}
</script>

<style>

</style>
