import gql from 'graphql-tag'

const updateWorkspace = gql`
mutation updateWorkspace(
  $name: String!
  $identifier_id: String!
  $photo: Upload
  $job_digit_counter: Float!
  $job_reset_count: String!
  $workspace_id: Float!
) {
  updateWorkspace(
    name: $name
    identifier_id: $identifier_id
    photo: $photo
    job_digit_counter: $job_digit_counter
    job_reset_count: $job_reset_count
    workspace_id: $workspace_id
  ) {
    status
    msg
    data
  }
}
`
export default updateWorkspace
