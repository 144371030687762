import gql from 'graphql-tag'

const productTypes = gql`
  query productTypes($workspace_id: Float!) {
    productTypes(workspace_id:$workspace_id) {
      id
      name
      color
    }
  }
`

export default productTypes
