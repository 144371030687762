import gql from 'graphql-tag'

const productRecommendations = gql`
  query productRecommendations (
    $workspace_id: Float!
    $customer_id: Float!
    $is_type_matrix: Boolean!
  ) {
    productRecommendations (
      workspace_id: $workspace_id
      customer_id: $customer_id
      is_type_matrix: $is_type_matrix
    ) {
      count
      products {
        id
        name
        description
        code_sku
        price
        status
        stock
        type {
          id
          name
          color
        }
        created_at
        updated_at
        deleted_at
      }
    }
  }
`

export default productRecommendations
