import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardText,{staticClass:"pt-2"},[_c('div',{staticClass:"d-flex flex-column"},[_c(VAlert,{staticClass:"mb-4 text-subtitle-2 py-2",attrs:{"border":"left","color":"primary","dense":"","text":"","type":"info"}},[_vm._v(" Daftar pengguna di "+_vm._s(_vm.workspace.name)+". Kamu bisa mengundang hingga 5 pengguna di workspace ini. ")]),_c(VRow,{staticClass:"mb-1"},[_c(VCol,{attrs:{"cols":"12","md":"auto"}},[(_vm.userRole.id <= 2)?_c(VBtn,{attrs:{"outlined":"","color":"primary","small":""},on:{"click":function($event){return _vm.$refs.emailInvite.show()}}},[_vm._v(" Undang Pengguna ")]):_c('span',{staticClass:"mr-2 text-subtitle-2 mt-1"},[_vm._v("Tautan Undangan")])],1),_c(VCol,{staticClass:"ml-md-auto",attrs:{"cols":"12","md":"auto"}},[(_vm.userRole.id <= 2)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"mr-4 text-subtitle-2 mt-1"},[_vm._v("Tautan Undangan")]),_c(VSwitch,{staticClass:"ma-0",attrs:{"inset":"","loading":_vm.loading,"hide-details":""},model:{value:(_vm.invitationSwitch),callback:function ($$v) {_vm.invitationSwitch=$$v},expression:"invitationSwitch"}})],1):_vm._e()])],1),_c(VSlideYTransition,[(_vm.invitationSwitch)?_c(VTextField,{attrs:{"dense":"","outlined":"","readonly":"","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"22"},on:{"click":_vm.refreshCode}},[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")]),_c(VIcon,{attrs:{"size":"22"},on:{"click":_vm.copyClipboard}},[_vm._v(" "+_vm._s(_vm.icons.mdiContentCopy)+" ")])],1)]},proxy:true}],null,false,4179574845),model:{value:(_vm.inviteUrl),callback:function ($$v) {_vm.inviteUrl=$$v},expression:"inviteUrl"}}):_vm._e()],1)],1),_c('ActiveSharedUser',{attrs:{"workspace":_vm.workspace}}),_c('email-invite',{ref:"emailInvite"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }