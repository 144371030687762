import { render, staticRenderFns } from "./WorkspaceSetting.vue?vue&type=template&id=ea3dfc28&"
import script from "./WorkspaceSetting.vue?vue&type=script&lang=js&"
export * from "./WorkspaceSetting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports